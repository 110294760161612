import { Suspense } from 'react';
import FullScreenLoading from './components/loading/FullScreenLoading';
import RootProvider from './providers/RootProvider';
import AppRouter from './router/AppRouter';
// const AppRouter = lazy(() => import('./router/AppRouter'));

export default function App() {
  return (
    <RootProvider>
      <Suspense fallback={<FullScreenLoading />}>
        <AppRouter />
      </Suspense>
    </RootProvider>
  );
}
