export enum Permissions {
  ROLES_MENU = 'Roles Menu',
  COURSE_CATEGORY_MENU = 'Course Category Menu',
  COURSES_MENU = 'Courses Menu',
  EXAM_MENU = 'Exam Menu',
  CERTIFICATES_MENU = 'Certificates Menu',
  DEPARTMENTS_MENU = 'Departments Menu',
  DESIGNATION_MENU = 'Designation Menu',
  FACULTIES_MENU = 'Faculties Menu',
  REPORT_MENU = 'Report Menu',
  MY_STUDENT_SCHEDULE = 'My Student Schedule',
  MANAGE_ASSESSMENT_SKILLS = 'Manage Assessment Skills',
  MANAGE_CERTIFICATE = 'Manage Certificate',
  SETTINGS_MENU = 'Settings Menu',
  MANAGE_COURSE_RECOMMENDATION = 'Manage Course Recommendation',
  MANAGE_ATTENDED_EXAM = 'Manage Attended Exam',
  EVALUATE_EXAM = 'Evaluate Exam',
  REQUESTS_MENU = 'Requests Menu',
  CREATE_SCHEDULE = 'Create Schedule',
  EDIT_SCHEDULE = 'Edit Schedule',
  CREATE_EXAM = 'Create Exam',
  EDIT_EXAM = 'Edit Exam',
  CREATE_CHAPTER = 'Create Chapter',
  EDIT_CHAPTER = 'Edit Chapter',
  REMOVE_CHAPTER = 'Remove Chapter',
  CREATE_RECOURSE = 'Create Recourse',
  EDIT_RECOURSE = 'Edit Recourse',
  VIEW_COURSE_EVALUATION = 'View Course Evaluation',
  CREATE_COURSE_EVALUATION = 'Create Course Evaluation',
  ATTEND_EXAM = 'Attend Exam',
  VIEW_EXAM = 'View Exam',
  Dashboard = 'Dashboard',
  VIEW_STUDENT_MODULE = 'View Student Module',
  VIEW_STUDENT_COURSE_DETAILS = 'View Student Course Details',
  FACULTY_REGISTRATION = 'Faculty Registration',
  EDIT_USER = 'Edit User',
  EDIT_COURSE = 'Edit Course',
  VIEW_CHAPTER = 'View Chapter',
  VIEW_PROFILE = 'View Profile',
  CREATE_COURSE = 'Create Course',
  VIEW_EXAM_LIST = 'View Exam List',
  COURSE_LEVEL_DETAILS = 'Course Level Details',
  VIEW_DEPARTMENT = 'View Department',
  COURSE_LIST = 'Course List',
  USERS_LIST = 'Users List',
  VIEW_REPORT_DETAILS = 'View Report Details',
  UPDATE_OFFLINE_EXAM_MARK = 'Update Offline Exam Mark',
  CREATE_COURSE_SCHEDULE = 'Create Course Schedule',
  EDIT_ATTENDANCE = 'Edit Attendance',
  VIEW_CERTIFICATE = 'View Certificate',
  ASSESSMENT_LIST = 'Assessment List',
  ADD_ASSESSMENT = 'Add Assessment',
  VIEW_SCHEDULE_STUDENTS = 'View Schedule Students',
  EDIT_ASSESSMENT = 'Edit Assessment',
  VIEW_SCHEDULE_ATTENDANCE = 'View Schedule Attendance',
}

export const ADMIN_PERMISSIONS = [
  Permissions.ROLES_MENU,
  Permissions.COURSE_CATEGORY_MENU,
  Permissions.COURSES_MENU,
  Permissions.EXAM_MENU,
  Permissions.CERTIFICATES_MENU,
  Permissions.DEPARTMENTS_MENU,
  Permissions.FACULTIES_MENU,
  Permissions.REPORT_MENU,
  Permissions.VIEW_REPORT_DETAILS,
  Permissions.MANAGE_ASSESSMENT_SKILLS,
  Permissions.MANAGE_ATTENDED_EXAM,
  Permissions.EVALUATE_EXAM,
  Permissions.REQUESTS_MENU,
  Permissions.DESIGNATION_MENU,
  Permissions.EDIT_COURSE,
  Permissions.CREATE_SCHEDULE,
  Permissions.EDIT_SCHEDULE,
  Permissions.CREATE_EXAM,
  Permissions.EDIT_EXAM,
  Permissions.CREATE_CHAPTER,
  Permissions.EDIT_CHAPTER,
  Permissions.REMOVE_CHAPTER,
  Permissions.CREATE_RECOURSE,
  Permissions.EDIT_RECOURSE,
  Permissions.VIEW_COURSE_EVALUATION,
  Permissions.Dashboard,
  Permissions.FACULTIES_MENU,
  Permissions.FACULTY_REGISTRATION,
  Permissions.EDIT_USER,
  Permissions.CREATE_CHAPTER,
  Permissions.EDIT_COURSE,
  Permissions.VIEW_CHAPTER,
  Permissions.VIEW_PROFILE,
  Permissions.CREATE_COURSE,
  Permissions.VIEW_EXAM_LIST,
  Permissions.COURSE_LEVEL_DETAILS,
  Permissions.VIEW_DEPARTMENT,
  Permissions.COURSE_LIST,
  Permissions.USERS_LIST,
  Permissions.CREATE_EXAM,
  Permissions.EDIT_EXAM,
  Permissions.UPDATE_OFFLINE_EXAM_MARK,
  Permissions.CREATE_COURSE_SCHEDULE,
  Permissions.EDIT_ATTENDANCE,
  Permissions.VIEW_CERTIFICATE,
  Permissions.ADD_ASSESSMENT,
  Permissions.ASSESSMENT_LIST,
  Permissions.VIEW_SCHEDULE_STUDENTS,
  Permissions.VIEW_SCHEDULE_ATTENDANCE,
  Permissions.DEPARTMENTS_MENU,
];

export const LINE_MANAGER_PERMISSIONS = [
  Permissions.MANAGE_COURSE_RECOMMENDATION,
  Permissions.REQUESTS_MENU,
  Permissions.MY_STUDENT_SCHEDULE,
  Permissions.CERTIFICATES_MENU,
  Permissions.CREATE_COURSE_EVALUATION,
  Permissions.ATTEND_EXAM,
  Permissions.VIEW_EXAM,
  Permissions.Dashboard,
  Permissions.VIEW_STUDENT_MODULE,
  Permissions.VIEW_STUDENT_COURSE_DETAILS,
  Permissions.VIEW_PROFILE,
  Permissions.VIEW_EXAM_LIST,
  Permissions.COURSE_LIST,
  Permissions.VIEW_COURSE_EVALUATION,
];

export const FACULTY_PERMISSIONS = [
  Permissions.COURSES_MENU,
  Permissions.EXAM_MENU,
  Permissions.CERTIFICATES_MENU,
  Permissions.MANAGE_ATTENDED_EXAM,
  Permissions.EVALUATE_EXAM,
  Permissions.REQUESTS_MENU,
  Permissions.EDIT_COURSE,
  Permissions.CREATE_SCHEDULE,
  Permissions.EDIT_SCHEDULE,
  Permissions.CREATE_EXAM,
  Permissions.EDIT_EXAM,
  Permissions.CREATE_CHAPTER,
  Permissions.EDIT_CHAPTER,
  Permissions.REMOVE_CHAPTER,
  Permissions.CREATE_RECOURSE,
  Permissions.EDIT_RECOURSE,
  Permissions.Dashboard,
  Permissions.CREATE_CHAPTER,
  Permissions.EDIT_COURSE,
  Permissions.VIEW_CHAPTER,
  Permissions.VIEW_PROFILE,
  Permissions.CREATE_COURSE,
  Permissions.VIEW_EXAM_LIST,
  Permissions.COURSE_LEVEL_DETAILS,
  Permissions.COURSE_LIST,
  Permissions.CREATE_EXAM,
  Permissions.EDIT_EXAM,
  Permissions.UPDATE_OFFLINE_EXAM_MARK,
  Permissions.CREATE_COURSE_SCHEDULE,
  Permissions.EDIT_ATTENDANCE,
  Permissions.VIEW_CERTIFICATE,
  Permissions.ADD_ASSESSMENT,
  Permissions.EDIT_ASSESSMENT,
  Permissions.ASSESSMENT_LIST,
  Permissions.VIEW_SCHEDULE_STUDENTS,
  Permissions.VIEW_SCHEDULE_ATTENDANCE,
  Permissions.VIEW_COURSE_EVALUATION,
];

export const STUDENT_PERMISSIONS = [
  Permissions.MY_STUDENT_SCHEDULE,
  Permissions.CERTIFICATES_MENU,
  Permissions.CREATE_COURSE_EVALUATION,
  Permissions.ATTEND_EXAM,
  Permissions.VIEW_EXAM,
  Permissions.Dashboard,
  Permissions.VIEW_STUDENT_MODULE,
  Permissions.VIEW_STUDENT_COURSE_DETAILS,
  Permissions.VIEW_PROFILE,
  Permissions.VIEW_EXAM_LIST,
  Permissions.COURSE_LIST,
  Permissions.VIEW_CERTIFICATE,
  Permissions.VIEW_COURSE_EVALUATION,
];
