export const route = {
  dashboard: 'dashboard',
  facultyRegistration: 'faculty-registration',
  profile: 'profile',
  createCourse: 'create-course',
  courseAssessment: 'course-assessment',
  courseCategory: 'course-category',
  certificateTemplates: 'certificate-templates',
  createCertificate: 'create-certificate',
  attendanceReport: 'attendance-report',
  roles: 'roles',
  exam: 'exams',
  departments: 'departments',
  courseList: 'course-list',
  studentCourses: 'student-courses',
  studentCourseDetails: (courseScheduleId: string) => `student-course-details/${courseScheduleId}`,
  studentJoinClass: 'student-join-class',
  studentClassModule: (id: string) => `student-class/${id}`,
  studentClassActivity: 'student-class-activity',
  studentExam: 'student-exam',
  attendExam: `attend-exam`,
  viewExam: `view-exam`,
  studentExamObjectives: 'student-exam-objectives',
  coursesDetails: (id: string) => `course-details/${id}`,
  editCourse: (id: string) => `edit-course/${id}`,
  chapterDetails: (id: string) => `chapter-details/${id}`,
  editChapter: (id: string) => `edit-chapter/${id}`,
  editUser: (id: string) => `edit-user/${id}`,
  courseChapterCreation: (id: string) => `course-chapter-creation/${id}`,
  login: 'login',
  users: 'users',
  createCourseExam: (id: string) => `create-course-exam/${id}`,
  editExam: (id: string) => `edit-exam/${id}`,
  exams: 'exams',
  certificates: 'certificates',
  editCertificate: (id: string) => `edit-certificate/${id}`,
  courseLevels: (id: string) => `course-levels/${id}`,
  createSchedule: (levelId: string) => `create-schedule/${levelId}`,
  editSchedule: (scheduleId: string) => `edit-schedule/${scheduleId}`,
  myStudentSchedule: 'student/my-courses',
  viewChapterDoc: `view-chapter-doc`,
  editAttendance: (id: string) => `edit-attendance/${id}`,
  assessmentSkills: 'assessment-skills',
  getCertificateDetails: (id: string) => `certificate-details/${id}`,
  settings: 'settings',
  scheduleStudents: (id: string) => `schedule-students/${id}`,
  addAssessment: 'add-assessment',
  assessments: 'assessments',
  addCourseRecommendation: 'add-course-recommendation',
  notifications: 'notifications',
  attendedExams: 'attended-exams',
  viewAttendedExam: (id: string) => `view-attended-exam/${id}`,
  editAssessment: (slug: string) => `edit-assessment/${slug}`,
  requests: 'requests',
  report: 'report',
  scheduleAttendance: (scheduleId: string) => `schedule-attendance/${scheduleId}`,
  certificatePrint: `certificate-print`,
  reportDetails: (id: string) => `/report/${id}`,
  designation: 'designation',
  courseEvaluation: (slug: string) => `course-evaluation/${slug}`,
  courseEvaluationList: 'course-evaluation',
  updateExamMarks: `update-exam-marks`,
};
