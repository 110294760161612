export const colors = {
  successGreen: '#4caf50',
  errorRed: '#f44336',
  warningYellow: '#ff9800',
  infoBlue: '#2196f3',
  white: '#ffffff',
  black: '#000000',
};

export const userRoles = {
  Faculty: 'Faculty',
  Students: 'Students',
} as const;
